import React, { Component } from 'react';
import './App.css';

import { GoogleLogin } from 'react-google-login';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import IndexLandingPage from './components/IndexLandingPage';
import Dashboard from './components/Dashboard';
import NoAccess from './components/NoAccess';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { redirect: null, isAuthenticated: false, userProfile: null };
  }

  logout = () => {
    this.setState({ redirect: null, isAuthenticated: false, userProfile: null });
  };


  onSuccess = (googleUser) => {
    var token = googleUser.getAuthResponse().id_token;

    var googleProfile = googleUser.getBasicProfile();
    var userProfile = { givenName: googleProfile.getGivenName(),
                        familyName: googleProfile.getFamilyName(),
                        email: googleProfile.getEmail(),
                        imageUrl: googleProfile.getImageUrl(),
                        token: token };

    console.log(userProfile.givenName + ' ' + userProfile.familyName);
    console.log(userProfile.email);
    console.log(userProfile.imageUrl);
    console.log("ID Token: " + userProfile.token);

    // verify that it's an authorized ID
    // TODO: do this check in the backend too at some point!

    var emailArray = userProfile.email.split('@');
    var emailDomain = emailArray[emailArray.length-1];

    if (userProfile.email==='xke@groveflow.com' 
        || userProfile.email==='hiro@ramenhero.com' 
        || userProfile.email==='aj@ramenhero.com' 
        || emailDomain==='ramenhero.com'
        || emailDomain==='groveflow.com') {
        
      this.setState({ redirect: "/dashboard", isAuthenticated: true, userProfile: userProfile });

    } else {

      this.setState({ redirect: "/noaccess", isAuthenticated: true, userProfile: userProfile });
    }


  }

  onFailure = (error) => {
    console.log(error);
  }

  render() {

    let googleButton = (
      <div className="sign-in-button-div">

        <GoogleLogin
          clientId="668477447762-fo5r2ak836qkdrkkut1t03eodvehkloj.apps.googleusercontent.com"
          buttonText="Sign In with Google to View Dashboard"
          prompt="select_account"
          onSuccess={this.onSuccess}
          onFailure={this.onFailure}
        />
      </div>
    );

    console.log("this.state.isAuthenticated: "+this.state.isAuthenticated);
    console.log("this.state.redirect: "+this.state.redirect);


    if (this.state.isAuthenticated && this.state.redirect) {
      // redirecting to input
      return (
        <Router>
          <Route exact path="/dashboard" render={props => 
            (<Dashboard {...props} userProfile={this.state.userProfile}/>)}/>
          <Route exact path="/noaccess" component={NoAccess}/>
          <Redirect to={this.state.redirect} />
        </Router>
      );

    }

    return (

      <Router>
        <Switch>


          <Route exact path="/" render={(props) => (
            <div className="App">
              <br/><br/><br/>
              <IndexLandingPage>
                {googleButton}
              </IndexLandingPage>
            </div>
          )} />
          
          <Route exact path="/dashboard" render={props => 
            (<Dashboard {...props} userProfile={this.state.userProfile}/>)}/>

          <Route exact path="/noaccess" component={NoAccess}/>


        </Switch>
      </Router>

    );

   }

}


export default App;

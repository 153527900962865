import React from 'react';
import { format } from 'date-fns';
import { productIds } from '../productIds';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    //localstorage to keep the info upon page refresh :)

    this.state = {
      userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
      orderCounts: {
        week: null,
        month: null,
        quarter: null,
        year: null,
      },
      revenues: {
        week: null,
        month: null,
        quarter: null,
        year: null,
      },
      productSales: {
        week: null,
        month: null,
        quarter: null,
        year: null,
      }
    }

    this.userProfile = { // default test profile
      givenName: "Test",
      familyName: "User",
      email: "test@user.com",
      imageUrl: "",
      token: ""
    };

    if (window.location.host.startsWith("localhost")) {
      this.baseURL = "http://localhost:8080";
    } else {
      this.baseURL = "https://ramenhero.ue.r.appspot.com";
    }

    if (this.props.userProfile!=null) {

      this.state.userProfile = this.props.userProfile;
      localStorage.setItem('userProfile', JSON.stringify(this.props.userProfile));

      this.userProfile = this.props.userProfile;

    } else if (this.state.userProfile!=null) {

      this.userProfile = this.state.userProfile;

    } else {
      this.state.userProfile = this.userProfile;
    }

    console.log("this.userProfile.token: "+this.userProfile.token);

    // bind all functions
    this.getAllOrderCounts = this.getAllOrderCounts.bind(this);
    this.getAllRevenues = this.getAllRevenues.bind(this);
    this.getAllProductSales = this.getAllProductSales.bind(this);

  }

  componentDidMount() {
    this.getAllOrderCounts();
    this.getAllRevenues();
    this.getZendeskMetrics();
  }

  // retrieves and stores number of orders in the past week/month/quarter/year
  async getAllOrderCounts() {
    // make request to API
    let url = new URL(this.baseURL + '/get-all-order-counts');
    const params = {
      email: this.state.userProfile.email,
      token: this.state.userProfile.token,
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const results = await fetch(url).then((res) => res.json());
    // console.log('order counts:', results);

    this.setState({
      orderCounts: {
        week: results.week,
        month: results.month,
        quarter: results.quarter,
        year: results.year,
      }
    });
  }

  // retrieves and stores revenue from the past week/month/quarter/year
  async getAllRevenues() {
    // make request to API
    let url = new URL(this.baseURL + '/get-all-revenues');
    const params = {
      email: this.state.userProfile.email,
      token: this.state.userProfile.token,
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const results = await fetch(url).then((res) => res.json());
    // console.log('revenues:', results);

    this.setState({
      revenues: {
        week: results.week,
        month: results.month,
        quarter: results.quarter,
        year: results.year,
      }
    });
  }

  async getAllProductSales(event) {
    // product id of item selected from drop down
    const productId = event.target.value;

    // if 'select value' is selected, reverts to empty
    if (productId.length === 0) {
      this.setState({
        productSales: {
          week: null,
          month: null,
          quarter: null,
          year: null,
        }
      })
      return;
    }

    // make request to API
    let url = new URL(this.baseURL + '/get-all-product-sales');
    const params = {
      product_id: productId,
      email: this.state.userProfile.email,
      token: this.state.userProfile.token,
    };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const results = await fetch(url).then((res) => res.json());
    // console.log('product sales:', results);

    this.setState({
      productSales: {
        week: results.week,
        month: results.month,
        quarter: results.quarter,
        year: results.year,
      }
    });
  }

  getZendeskMetrics(){
    var formBody = new URLSearchParams();
    formBody.append("email", this.userProfile.email);
    formBody.append("token", this.userProfile.token);
    
    const requestOptions = {
      method:'POST',
      headers:{"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"},
      body:formBody
    }
    //fetch(this.baseURL+'/update-tag-zendesk-metrics', {method:'GET', headers:{"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"}});
    //fetch(this.baseURL+'/update-response-zendesk-metrics', {method:'GET', headers:{"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"}});
    //this if statement is built to allow multiple API implementations. currently not in use.
    if(window.location.host.startsWith("localhost")){
      fetch(this.baseURL+'/get-all-zendesk-metrics', requestOptions)
        .then(res=>res.json())
        .then(
          (results) => {
            try {
              //console.log(results);
              this.setState({'zendesk_metrics':results})
            } catch (err){
              console.log("Error: "+err);
            }
          }
        )
    } else {
      //can be changed later for different apis! just fetch all the zendesk data.
      fetch('https://ramenhero.ue.r.appspot.com/get-all-zendesk-metrics', requestOptions)
      .then(res=>res.json())
      .then(
        (results) => {
          try {
            //console.log(results);
            this.setState({'zendesk_metrics':results})
          } catch (err){
            console.log("Error: "+err);
          }
        }
      )
    }
  }

  render() {
    var displayDate = format(new Date(), 'iiii MMM d, yyyy h:mm bb')
    //ZENDESK CUSTOMER DATA FORMATTING
    //we store the data by class, since this is how we wish to separate out the tables.
    //each class further separates the datapoints into two arrays. the first array contains the column names,
    //and the second array contains the values
    var zendeskData = {'year':[[], []], 'month':[[], []], 'day':[[], []], 'week': [[], []], 'quarter':[[], []], 'total':[[], []]};
    var zendeskTags = {'year':[[], []], 'month':[[], []], 'day':[[], []], 'week':[[], []], 'quarter':[[], []]}
    var zendeskSatisfaction = {'year':[[], []], 'month':[[], []], 'day':[[], []], 'week':[[],[]], 'quarter':[[], []]};
    //only proceed is the component has mounted (zendesk metrics are present)
    if(this.state['zendesk_metrics']){
      //console.log(this.state['zendesk_metrics'].metrics);
      //assemble the data from the state into the zendeskdata
      for(var i in this.state['zendesk_metrics'].metrics){
        var current = this.state['zendesk_metrics'].metrics[i];
        //console.log(current);
        //console.log(current.type);
        if(current.class==='response' || current.class==='misc'){
          zendeskData[current.type][0].push(current.name);
          zendeskData[current.type][1].push(current.val);
        } else if(current.class==='tag'){
          zendeskTags[current.type][0].push(current.name);
          zendeskTags[current.type][1].push(current.val);
        } else if(current.class==='satisfaction'){
          zendeskSatisfaction[current.type][0].push(current.name);
          zendeskSatisfaction[current.type][1].push(current.val);
        }
      }
      var zendeskDataToRender = [];
      var types = ['quarter', 'year', 'month', 'week', 'day', 'total'];
      var classNames = ['None', 'result_positive', 'result_negative', 'result_none'];
      console.log(zendeskData);
      console.log(zendeskTags);
      console.log(zendeskSatisfaction);
      //now that data is organized, we can reformat it into renderable data
      for(var i in types){
        //console.log(zendeskData[i]);
        //zendeskdatatorender contains tables as each of the entries in its arrays. for each array element,
        //the first element is an array that contains column names, wrapped in th html, and
        //the second element is an array that contains the corresponding data, wrapped in td html.
        //add formatting here!
        zendeskDataToRender.push([zendeskData[types[i]][0].map((datapoint, index)=>{
          return <th>{datapoint}</th>;
        }), zendeskData[types[i]][1].map((datapoint, index)=>{
          return <td className = {classNames[index]}>{datapoint}</td>;
        })])
      }
      var finalRender = [];
      for(var go in zendeskDataToRender){
        finalRender.push(
        <div>
          <table id="dashboard">
            <tbody>
              <tr className="big_font">
                {zendeskDataToRender[go][0]}
              </tr>
              <tr className="big_number">
                {zendeskDataToRender[go][1]}
              </tr>
            </tbody>
          </table>
          <br></br>
        </div>);
      }
    }


    const formatterDollars = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    // display - if value is null, undefined, or nan
    const displayCount = count => count || count === 0? count : "-";
    const displayHours = hours => hours || hours === 0? Math.round(hours) : "-";
    const displayDollars = dollars => dollars || dollars === 0? formatterDollars.format(dollars) : "-";

    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="main_title_1">
                    <br/><br/>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <img className="small_image" src="img/ramenhero.jpg" alt="Ramen Hero"/>
                          </td>
                          <td>
                            <h3 className="title_header font-mont">Company Dashboard</h3>
                            <div className="display_date font-mont">{displayDate}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br></br>
                    <h3 className="main_question"> 
                      <div className="section_header">Operation Metrics</div>

                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Orders This Week</th>
                            <th>Orders This Month</th>
                            <th>Orders This Quarter</th>
                            <th>Orders This Year</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "steelblue"}}>{displayCount(this.state.orderCounts.week)}</td>
                            <td style={{backgroundColor: "seagreen"}}>{displayCount(this.state.orderCounts.month)}</td>
                            <td style={{backgroundColor: "orange"}}>{displayCount(this.state.orderCounts.quarter)}</td>
                            <td style={{backgroundColor: "red"}}>{displayCount(this.state.orderCounts.year)}</td>
                          </tr>
                        </tbody>
                      </table> 


                      <br></br>
                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Revenue This Week</th>
                            <th>Revenue This Month</th>
                            <th>Revenue This Quarter</th>
                            <th>Revenue This Year</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "steelblue"}}>{displayDollars(this.state.revenues.week)}</td>
                            <td style={{backgroundColor: "seagreen"}}>{displayDollars(this.state.revenues.month)}</td>
                            <td style={{backgroundColor: "orange"}}>{displayDollars(this.state.revenues.quarter)}</td>
                            <td style={{backgroundColor: "red"}}>{displayDollars(this.state.revenues.year)}</td>
                          </tr>
                        </tbody>
                      </table>

                      <br></br>

                      <div>
                        <select onChange={this.getAllProductSales}>
                          <option value="">Select Product:</option>
                          <option value={productIds.totally_tonkotsu}>Totally Tonkotsu</option>
                          <option value={productIds.burning_love}>Burning Love</option>
                          <option value={productIds.misosaurus}>Misosaurus</option>
                          <option value={productIds.magic_mushroom}>Magic Mushroom</option>
                          <option value={productIds.crying_samurai}>Crying Samurai</option>
                          <option value={productIds.mad_dashi}>Mad Dashi</option>
                          <option value={productIds.matchamaker}>Matchamaker</option>
                          <option value={productIds.hippie_van}>Hippie Van</option>
                          <option value={productIds.miso_impossible}>Miso Impossible</option>
                          <option value={productIds.very_veggie}>Very Veggie</option>
                        </select>
                      </div>
                      <br></br>

                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Sold This Week</th>
                            <th>Sold This Month</th>
                            <th>Sold This Quarter</th>
                            <th>Sold This Year</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "steelblue"}}>{displayCount(this.state.productSales.week)}</td>
                            <td style={{backgroundColor: "seagreen"}}>{displayCount(this.state.productSales.month)}</td>
                            <td style={{backgroundColor: "orange"}}>{displayCount(this.state.productSales.quarter)}</td>
                            <td style={{backgroundColor: "red"}}>{displayCount(this.state.productSales.year)}</td>
                          </tr>
                        </tbody>
                      </table>

                      <br></br>

                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Average Order Value This Week</th>
                            <th>AOV This Month</th>
                            <th>AOV This Quarter</th>
                            <th>AOV This Year</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "steelblue"}}>{displayDollars(this.state.revenues.week/this.state.orderCounts.week)}</td>
                            <td style={{backgroundColor: "seagreen"}}>{displayDollars(this.state.revenues.month/this.state.orderCounts.month)}</td>
                            <td style={{backgroundColor: "orange"}}>{displayDollars(this.state.revenues.quarter/this.state.orderCounts.quarter)}</td>
                            <td style={{backgroundColor: "red"}}>{displayDollars(this.state.revenues.year/this.state.orderCounts.year)}</td>
                          </tr>
                        </tbody>
                      </table>

                      <br></br>


                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Inventory Available</th>
                            <th>Inventory Allocated</th>
                            <th>Inventory Out-of-Stock</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "gray"}}>10</td>
                            <td style={{backgroundColor: "gray"}}>1,000</td>
                            <td style={{backgroundColor: "gray"}}>50</td>
                          </tr>
                        </tbody>
                      </table>
                    </h3>

                    <br></br>

                    <h3 className="main_question"> 
                      <div className="section_header">Customer Success Metrics</div>


                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Tickets<br></br>(This Week)</th>
                            <th>Avg First-Time Response<br></br>(This Week)</th>
                            <th>Avg Resolved Ticket Time<br></br>(This Week)</th>
                          </tr>
                          <tr className="big_number">
                            <td className="result_yellow">{zendeskData['week'][1][0]}</td>
                            <td className="result_green">{displayHours(zendeskData['week'][1][2])} hours</td>
                            <td style={{backgroundColor: "steelblue"}}>{displayHours(zendeskData['week'][1][1])} hours</td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>
                      
                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Tickets<br></br>(This Month)</th>
                            <th>Avg First-Time Response<br></br>(This Month)</th>
                            <th>Avg Resolved Ticket Time<br></br>(This Month)</th>
                          </tr>
                          <tr className="big_number">
                            <td className="result_yellow">{zendeskData['month'][1][0]}</td>
                            <td className="result_green">{displayHours(zendeskData['month'][1][2])} hours</td>
                            <td style={{backgroundColor: "steelblue"}}>{displayHours(zendeskData['month'][1][1])} hours</td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>

                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Tickets (This Quarter)</th>
                            <th>Avg First-Time Response (This Quarter)</th>
                            <th>Avg Resolved Ticket Time (This Quarter)</th>
                          </tr>
                          <tr className="big_number">
                            <td className="result_yellow">{zendeskData['quarter'][1][0]}</td>
                            <td className="result_green">{displayHours(zendeskData['quarter'][1][2])} hours</td>
                            <td style={{backgroundColor: "steelblue"}}>{displayHours(zendeskData['quarter'][1][1])} hours</td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>


                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Current Unresolved Tickets</th>
                            <th>Current Unreplied Tickets</th>
                            <th>Current Unreplied and Unresolved Tickets</th>
                          </tr>
                          <tr className="big_number">
                            <td className="result_yellow">{zendeskData['total'][1][0]}</td>
                            <td className="result_red">{zendeskData['total'][1][1]}</td>
                            <td className="result_green">{zendeskData['total'][1][2]}</td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>

                      {/* {finalRender ? finalRender : null} */}
                      <div className="section_header">Customer Issues this Quarter</div>
                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Delivery</th>
                            <th>Negative Feedback</th>
                            <th>Thawing Upon Delivery</th>
                          </tr>
                          <tr className="big_number">
                            <td className="result_yellow">{zendeskTags['quarter'][1][32]}</td>
                            <td className="result_red">{zendeskTags['quarter'][1][15]}</td>
                            <td className="result_green">{zendeskTags['quarter'][1][78]}</td>
                          </tr>
                        </tbody>
                      </table>

                    </h3>

                    <br></br>


                    <h3 className="main_question"> 
                      <div className="section_header">Site Metrics (Last 30 Days)</div>

                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Conversion Rate %</th>
                            <th>Returning Customer Rate %</th>
                            <th>CAC by Channel</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "gray"}}>10%</td>
                            <td style={{backgroundColor: "gray"}}>20%</td>
                            <td style={{backgroundColor: "gray"}}>$5</td>
                          </tr>
                        </tbody>
                      </table>

                      <br></br>
                      <div>
                        <select>
                          <option value="0">Select Cohort:</option>
                          <option value="1">Facebook</option>
                          <option value="2">Instagram</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                      <br></br>
                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Sessions</th>
                            <th>Repurchase Rate</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "gray"}}>500</td>
                            <td style={{backgroundColor: "gray"}}>10%</td>
                          </tr>
                        </tbody>
                      </table>


                      <br></br>
                      <table id="dashboard">
                        <tbody>
                          <tr className="big_font">
                            <th>Churn Rate (30 Days)</th>
                            <th>Churn Rate (60 Days)</th>
                            <th>Churn Rate (90 Days)</th>
                          </tr>
                          <tr className="big_number">
                            <td style={{backgroundColor: "gray"}}>10%</td>
                            <td style={{backgroundColor: "gray"}}>20%</td>
                            <td style={{backgroundColor: "gray"}}>30%</td>
                          </tr>
                        </tbody>
                      </table>

                    </h3>

                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default Dashboard 
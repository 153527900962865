import React, { Component } from 'react';


class NoAccess extends Component {

  render () {
    return (

      <div className="wrapper_centering">
        <div className="container_centering">
          <div className="container">
              <div className="row justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="main_title_1">
                    <br/><br/>
                    <h3>Oops, sorry!</h3>
                    <p>You're not authorized to view the dashboard.</p>
                    <p>Please try <a style={{color: "yellow"}} href="/">signing in again</a> with your @ramenhero.com email address. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

    );
  }
}

export default NoAccess 
import React from 'react';

class IndexLandingPage extends React.Component {

  render () {
    return (

      <div className="wrapper_centering">
        <div className="container_centering">
            <div className="main_title_1 text-center">
              <a href="https://www.ramenhero.com/" target="_blank" rel="noopener noreferrer">
                <img className="main_image" src="img/ramenhero.jpg" alt="Ramen Hero"/>
              </a>
              <br></br><br></br><br></br>
              <h3 className="font-mont">Company Dashboard</h3>

              {/*<!-- entry point to app -->*/}
              {this.props.children}

            </div>
            <br></br>

          </div>

      </div>

    );
  }
}

export default IndexLandingPage 
export const productIds = {
    totally_tonkotsu: "4431640166534",
    burning_love: "4469258223750",
    misosaurus: "4469253210246",
    magic_mushroom: "4469258682502",
    crying_samurai: "4469260779654",
    mad_dashi: "4509123707014",
    matchamaker: "4469259763846",
    hippie_van: "4426626564230",
    miso_impossible: "4426622992518",
    very_veggie: "4426632462470",
}